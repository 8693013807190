.crm {
    font-family: 'Whitney A', 'Whitney B', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue',
        'Ubuntu', sans-serif;

    &-search {
        border-top: 2px solid #e7f1fa;
        padding: 15px;

        &-field {
            padding: 10px;
            background: #f1f8ff;
            border: 1px solid #ccebea;
            display: flex;

            .bp4-input-group {
                flex-grow: 1;
            }

            .bp4-input {
                box-shadow: inset 0 0 0 2px rgba(0, 119, 204, 0.3);
            }
        }

        &-results {
            display: flex;

            &-list {
                flex-basis: 0;
                flex-grow: 3;
                margin-top: 25px;

                &-loading,
                &-noresult {
                    margin: 50px 0;
                }

                &-loadmore {
                    margin-top: 30px;
                }
            }

            &-loading {
                opacity: 0.5;
            }

            &-head {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border: 1px solid #c9c7c7;
                color: #5c7097;
                font-weight: bold;

                &-sort {
                    flex-basis: 0;
                    flex-grow: 1;
                    padding: 5px;

                    &-button {
                        border: 0 !important;
                        color: #5c7097 !important;
                        font-weight: bold !important;
                    }
                }

                &-location {
                    flex-basis: 0;
                    width: 180px;
                    min-width: 180px;
                    padding: 5px;
                }

                &-activity,
                &-status {
                    flex-basis: 0;
                    width: 110px;
                    min-width: 110px;
                    padding: 5px;
                    text-align: center;
                }
            }

            &-item {
                margin-top: 15px;
                border: 1px solid #c9c7c7;

                &-details {
                    background: #f8fbfe;
                    display: flex;
                    align-items: stretch;
                    cursor: pointer;

                    &:hover {
                        background: #ebf4fd;
                    }
                }

                &-picture {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    width: 65px;
                    min-width: 65px;
                    flex-basis: 0;
                    flex-grow: 0;

                    img {
                        width: 45px;
                        height: 45px;
                        border-radius: 23px;
                        margin-right: 10px;
                    }
                }

                &-name {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    padding: 3px 0;
                }

                &-mainname {
                    font-size: 18px;
                    margin-bottom: 5px;

                    a,
                    a:hover {
                        color: #1c2127;
                    }
                }

                &-subname {
                    font-size: 12px;
                    font-weight: 600;

                    a,
                    a:hover {
                        color: #5c7097;
                    }
                }

                &-location {
                    flex-basis: 0;
                    display: flex;
                    align-items: center;
                    font-family: 'Open Sans', 'Open Sans', Segoe UI, Source Sans Pro, Calibri, Candara, Arial,
                        sans-serif;
                    font-size: 12px;
                    width: 180px;
                    min-width: 180px;
                    padding: 5px;

                    .fi {
                        margin-left: 5px;
                    }
                }

                &-activity,
                &-status {
                    flex-basis: 0;
                    font-family: 'Open Sans', 'Open Sans', Segoe UI, Source Sans Pro, Calibri, Candara, Arial,
                        sans-serif;
                    font-size: 12px;
                    width: 110px;
                    min-width: 110px;
                    text-align: center;
                    padding: 5px;
                }

                &-activity {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    time {
                        display: block;

                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                }

                &-status {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 500;

                    &-c {
                        background: #d6f4d9;
                        color: #335c41;
                    }

                    &-m {
                        background: #d6f4f3;
                        color: #33505c;
                    }

                    &-u {
                        background: #ffffcc;
                        color: #5c5c33;
                    }

                    &-label {
                        flex-basis: 0;
                        flex-grow: 1;
                    }

                    &-action {
                        flex-grow: 0;
                        flex-basis: 0;
                    }

                    &-button {
                        border: 0 !important;
                        color: #6c6c6c !important;
                        padding: 0 !important;
                        min-width: 20px !important;
                        font-size: 20px !important;
                    }
                }

                &-tags {
                    padding: 8px 10px;

                    &-more {
                        border: 0 !important;
                        color: #6c6c6c !important;
                    }

                    &-none {
                        display: inline-flex;
                        align-items: center;
                        font-size: 11px;
                        font-style: italic;
                        font-family: 'Open Sans', Segoe UI, Source Sans Pro, Calibri, Candara, Arial, sans-serif;
                        margin: 2px 0 2px 10px;
                        padding: 3px 6px;
                        color: #999;
                    }

                    &-edit {
                        padding: 10px;
                        width: 450px;
                    }

                    .bp4-button {
                        margin-top: -3px;
                    }
                }

                &-tag {
                    display: inline-flex;
                    align-items: center;
                    padding: 3px 6px;
                    margin: 2px 0 2px 10px;
                    background: #d6f4f3;
                    border-radius: 3px;
                    color: #33505c;
                    font-family: 'Open Sans', Segoe UI, Source Sans Pro, Calibri, Candara, Arial, sans-serif;
                    font-size: 11px;
                    font-weight: 700;

                    i {
                        margin-right: 5px;
                    }
                }

                &-more {
                    padding: 10px;
                    display: flex;
                }

                &-history {
                    flex-basis: 0;
                    flex-grow: 67;
                }

                &-profile {
                    flex-basis: 0;
                    flex-grow: 33;
                    border-left: 1px solid #d6f4f3;
                    padding: 20px 30px;

                    &-extradata {
                        display: inline-flex;
                        align-items: center;
                        padding: 3px 6px;
                        margin-right: 3px;
                        border-radius: 3px;
                        font-family: 'Open Sans', Segoe UI, Source Sans Pro, Calibri, Candara, Arial, sans-serif;
                        font-size: 10px;
                        font-weight: 700;
                        background: #e8eff6;
                        color: #33505c !important;

                        i {
                            margin-right: 5px;
                        }
                    }

                    &-subscription {
                        display: inline-flex;
                        align-items: center;
                        padding: 3px 6px;
                        margin-bottom: 4px;
                        border-radius: 3px;
                        font-family: 'Open Sans', Segoe UI, Source Sans Pro, Calibri, Candara, Arial, sans-serif;
                        font-size: 11px;
                        font-weight: 700;

                        i {
                            margin-right: 5px;
                        }

                        &-subscribed {
                            background: #d6f4d9;
                            color: #335c41;
                        }

                        &-unsubscribed {
                            background: #ffffcc;
                            color: #5c5c33;
                        }
                    }

                    &-section {
                        margin-top: 20px;
                        font-weight: bold;

                        &-label {
                            color: #c1c1c1;
                        }

                        &-socials {
                            font-size: 22px;

                            a {
                                color: #666;
                                text-decoration: none;
                                display: inline-block;
                                margin-right: 5px;

                                &:hover {
                                    color: #222;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }

            &-side {
                flex-basis: 0;
                flex-grow: 1;
                margin: 25px 0 0 25px;
            }

            &-actions,
            &-facets {
                flex-basis: 0;
                flex-grow: 1;
                background: #f1f8ff;
                padding: 15px;
            }

            &-actions {
                margin-bottom: 20px;

                &-buttons {
                    display: inline-block;

                    .bp4-button {
                        background: #fff;
                    }

                    &-label {
                        margin-bottom: 3px;
                        color: #5f6b7c;
                        font-size: 12px;
                    }
                }

                &-dialog {
                    text-align: center;
                    padding: 30px 20px 10px 20px;

                    .bp4-button {
                        margin: 0 7px;
                    }
                }
            }

            &-row {
                display: flex;
                align-items: center;
            }

            &-nbhits {
                flex-grow: 1;
                flex-basis: 0;
                padding-right: 20px;
                font-weight: bold;
            }

            &-facet {
                margin-bottom: 20px;

                &-title {
                    text-transform: uppercase;
                    color: #33505c;
                    font-size: 14px;
                    font-weight: bold;
                    border-bottom: 1px solid #cfd6d5;
                    padding: 5px 0;
                }

                &-search {
                    margin-top: 5px;
                }

                &-value {
                    display: flex;
                    align-items: center;
                    padding: 5px 10px 7px 10px;
                    cursor: pointer;

                    &:hover {
                        background: #d8ecfd;
                    }

                    &-include {
                        color: #161c2e;
                        font-weight: bold;
                        background: #fff;

                        &:hover {
                            background: #fff !important;
                        }
                    }

                    &-exclude {
                        color: #ee476b;
                        font-weight: bold;
                        background: #fff;

                        &:hover {
                            background: #fff !important;
                        }
                    }

                    &-label {
                        flex-basis: 0;
                        flex-grow: 1;
                    }

                    &-count {
                        flex-basis: 0;
                        flex-grow: 0;
                    }

                    &-actions {
                        flex-basis: 0;
                        flex-grow: 0;
                        margin-left: 5px;

                        &-button {
                            border: 0 !important;
                            color: #161c2e !important;
                        }
                    }
                }
            }
        }
    }
}
